import { DOCUMENT, NgClass } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';

import { NgScrollbar } from 'ngx-scrollbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '../../../core/services/auth.service';
import { LanguageService } from '../../../core/services/language.service';
import { Role } from '../../../core/models/role.enum';
import { FeatherIconsComponent } from '../feather-icons/feather-icons.component';
import { UnsubscribeOnDestroyAdapter } from '../../adapter/UnsubscribeOnDestroyAdapter';
import { TranslateModule } from '@ngx-translate/core';
import { CommonService } from '../../../core/services/common.service';
import { environment } from '../../../../environments/environment';



interface Notifications {
  message: string;
  time: string;
  icon: string;
  color: string;
  status: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [RouterLink,NgScrollbar,MatMenuModule,MatButtonModule, FeatherIconsComponent, NgClass, TranslateModule],

})
export class HeaderComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  env = environment.env

  //public config!: InConfiguration;
  userImg?: string;
  homePage?: string;
  isNavbarCollapsed = false;
  flagvalue: string | string[] | undefined;
  countryName: string | string[] = [];
  langStoreValue?: string;
  defaultFlag?: string;
  isOpenSidebar?: boolean;
  docElement?: HTMLElement;
  isFullScreen = false;


  logOutMenu: string
  accountMenu: string

  userName : string = '';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    public commonService: CommonService,
    private router: Router,
    public languageService: LanguageService
  ) {
    super();
    this.logOutMenu = 'MENUITEMS.LOGOUT.TEXT'
    this.accountMenu = 'MENUITEMS.ACCOUNT.TEXT'

  }

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.svg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.svg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.svg', lang: 'de' },
  ];
  notifications: Notifications[] = [
    {
      message: 'Please check your mail',
      time: '14 mins ago',
      icon: 'mail',
      color: 'nfc-green',
      status: 'msg-unread',
    },
    {
      message: 'New Patient Added..',
      time: '22 mins ago',
      icon: 'person_add',
      color: 'nfc-blue',
      status: 'msg-read',
    },
    {
      message: 'Your leave is approved!! ',
      time: '3 hours ago',
      icon: 'event_available',
      color: 'nfc-orange',
      status: 'msg-read',
    },
    {
      message: 'Lets break for lunch...',
      time: '5 hours ago',
      icon: 'lunch_dining',
      color: 'nfc-blue',
      status: 'msg-read',
    },
    {
      message: 'Patient report generated',
      time: '14 mins ago',
      icon: 'description',
      color: 'nfc-green',
      status: 'msg-read',
    },
    {
      message: 'Please check your mail',
      time: '22 mins ago',
      icon: 'mail',
      color: 'nfc-red',
      status: 'msg-read',
    },
    {
      message: 'Salary credited...',
      time: '3 hours ago',
      icon: 'paid',
      color: 'nfc-purple',
      status: 'msg-read',
    },
  ];
  ngOnInit() {
   // this.config = this.configService.configData;
    const userRole = Role.Admin
    this.userImg = '';

    this.docElement = document.documentElement;

    this.homePage = 'home';


    this.langStoreValue = localStorage.getItem('lang') as string;
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.defaultFlag = 'assets/images/flags/us.svg';
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }


    //Usuario
    this.userName = JSON.parse(localStorage.getItem("currentUser") || '').name
    this.userName = this.userName.toLowerCase().replace(/(?:^|\s)\S/g, match => match.toUpperCase())
    this.commonService.nombre = this.userName      
    if(this.userName===''){
        const user = localStorage.getItem('currentUser')
        if(user){
          this.userName = JSON.parse(user).name
        }
      }
  }

  callFullscreen() {
    if (!this.isFullScreen) {
      if (this.docElement?.requestFullscreen != null) {
        this.docElement?.requestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
  }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);
  }
  mobileMenuSidebarOpen(event: Event, className: string) {

    console.log('event.target',event.target)
    console.log('className',className)


    const hasClass = (event.target as HTMLInputElement).classList.contains(
      className
    );


    console.log('classList',(event.target as HTMLInputElement).classList)

    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }


  callSidemenuCollapse() {
      var collapsed = localStorage.getItem('collapsed_menu')
      console.log('collapsed',collapsed)

    // if(collapsed){
    //   if (window.innerWidth < 1025) {


    //     if(collapsed === 'true'){

    //       console.log('collapsed true pequeño',collapsed)

    //       this.renderer.addClass(this.document.body, 'side-closed');
    //       this.renderer.addClass(this.document.body, 'submenu-closed');
    //       this.renderer.addClass(this.document.body, 'overlay-open');
    //       localStorage.setItem('collapsed_menu', 'false');
    //     }else{

    //       console.log('collapsed false pequeño',collapsed)

    //       this.renderer.removeClass(this.document.body, 'side-closed');
    //       this.renderer.removeClass(this.document.body, 'submenu-closed');
    //       this.renderer.removeClass(this.document.body, 'overlay-open');
    //       localStorage.setItem('collapsed_menu', 'true');
    //     }

    //   }else{
    //     if(collapsed === 'true'){
    //       console.log('collapsed true grande',collapsed)

    //       this.renderer.removeClass(this.document.body, 'side-closed');
    //       this.renderer.removeClass(this.document.body, 'submenu-closed');
    //       localStorage.setItem('collapsed_menu', 'false');

    //     }else{
    //       console.log('collapsed false grande',collapsed)

    //       this.renderer.addClass(this.document.body, 'side-closed');
    //       this.renderer.addClass(this.document.body, 'submenu-closed');
    //       localStorage.setItem('collapsed_menu', 'true');

    //     }

    //    }
    // }

    if (window.innerWidth < 1025){
      const hasClass = this.document.body.classList.contains('overlay-open');
      if (hasClass) {
        this.renderer.removeClass(this.document.body, 'overlay-open');
        localStorage.setItem('collapsed_menu', 'false');
      } else {
        this.renderer.addClass(this.document.body, 'overlay-open');
        localStorage.setItem('collapsed_menu', 'false');
      }

    }else{
      const hasClass = this.document.body.classList.contains('side-closed');
      console.log('hasClass',hasClass)
      if (hasClass) {
        this.renderer.removeClass(this.document.body, 'side-closed');
        this.renderer.removeClass(this.document.body, 'submenu-closed');
        localStorage.setItem('collapsed_menu', 'false');
      } else {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
        localStorage.setItem('collapsed_menu', 'true');
      }
    }
    this.isOpenSidebar = !this.isOpenSidebar;
  }


  logout() {
    this.subs.sink = this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/login']);
      }
    });
  }


  miAccount() {
    console.log("miAccount");

        this.router.navigate(['/usuarios/mi-cuenta']);

  }
}
