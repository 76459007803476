<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover()" (mouseleave)="mouseOut()" style="margin-top: 4px;">
    <!-- Menu -->
    <div class="menu" >
      <ng-scrollbar [style.height]="listMaxHeight + 'px'" visibility="hover">
        <ul class="list">
          <!-- <li class="sidebar-user-panel">
          <div class="navbar-header" style="padding: 4px;">
            <a class="navbar-brand"  style="height: auto;">
              <img src="assets/images/montepio.png" class="img-fluid"  alt="" />
            </a>
          </div>
        </li> -->
          <li class="sidebar-user-panel" style="margin-top: 10px;">
            <!-- <div class="user-panel">
              <div class=" image">
                <img src='assets/images/icon.png'  alt="User Image" />
              </div>
            </div> -->
             <div class="profile-usertitle">
              <div >
                <a routerLink="/inicio">
                  <img src="assets/images/montepio.png" class="img-fluid"  alt="" />
                </a>
              </div>
            </div>
          </li>
          <!-- Top Most level menu -->
          @for (sidebarItem of sidebarItems; track sidebarItem) {


            <span class="notranslate">
            <li [routerLinkActive]="sidebarItem.submenu.length !== 0 ? 'active' : 'active-top'">
              @if (sidebarItem.groupTitle === true) {
              <div class="header">{{sidebarItem.title | translate}}</div>
              }
              @if (!sidebarItem.groupTitle;) {
              <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" [ngClass]="[sidebarItem.class]"
                (click)="callToggleMenu($event, sidebarItem.submenu.length)" class="menu-top">
                <i [ngClass]="[sidebarItem.iconType]">{{sidebarItem.icon}}</i>
              <span class="hide-menu">{{sidebarItem.title | translate}}
                </span>
                @if (sidebarItem.badge !== '' ) {
                <span [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
                }
              </a>
              }
              <!-- First level menu -->
              @if (sidebarItem.submenu.length > 0) {
              <ul class="ml-menu">
                @for (sidebarSubItem1 of sidebarItem.submenu; track sidebarSubItem1) {
                <li [routerLinkActive]="sidebarSubItem1.submenu.length > 0 ? '' : 'active'">
                  <a [routerLink]="sidebarSubItem1.submenu.length > 0 ? null : [sidebarSubItem1.path]"
                    (click)="callToggleMenu($event,sidebarSubItem1.submenu.length)" [ngClass]="[sidebarSubItem1.class]">
                    {{sidebarSubItem1.title | translate}}
                  </a>
                  <!-- Second level menu -->
                  @if (sidebarSubItem1.submenu.length > 0) {
                  <ul class="ml-menu-2">
                    @for (sidebarSubItem2 of sidebarSubItem1.submenu; track sidebarSubItem2) {
                    <li [routerLinkActive]="sidebarSubItem2.submenu.length > 0 ? '' : 'active'">
                      <a [routerLink]="sidebarSubItem2.submenu.length > 0 ? null : [sidebarSubItem2.path]"
                        (click)="callToggleMenu($event,sidebarSubItem2.submenu.length)"
                        [ngClass]="[sidebarSubItem2.class]">
                        {{sidebarSubItem2.title | translate}}
                      </a>
                      <!-- Third level menu -->
                      @if (sidebarSubItem2.submenu.length > 0) {
                      <ul class="ml-menu-3">
                        @for (sidebarSubItem3 of sidebarSubItem2.submenu; track sidebarSubItem3) {
                        <li [routerLinkActive]="sidebarSubItem3.submenu.length > 0 ? '' : 'active'">
                          <a [routerLink]="sidebarSubItem3.submenu.length > 0 ? null : [sidebarSubItem3.path]"
                            (click)="callToggleMenu($event,sidebarSubItem3.submenu.length)"
                            [ngClass]="[sidebarSubItem3.class]">
                            {{sidebarSubItem3.title | translate}}
                          </a>
                        </li>
                        }
                      </ul>
                      }
                    </li>
                    }
                  </ul>
                  }
                </li>
                }
              </ul>
              }
            </li>
            </span>



          }

          <span class="notranslate">
          <li>
            <a class="" (click)="logout()" class="menu-top">
              <i class="material-icons-outlined">power_settings_new</i>
              <span class="hide-menu">{{logOutText | translate}}
              </span>
            </a>
          </li>
          </span>
         
        </ul>
      </ng-scrollbar>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>
