import { Routes } from '@angular/router';
import { AuthLayoutComponent } from './shared/layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './shared/layout/main-layout/main-layout.component';
import { Role } from './core/models/role.enum';
import { AuthGuard } from './core/guard/auth.guard';
import { Page404Component } from './shared/components/page404/page404.component';
import { ConstruccionComponent } from './pages/construccion/construccion.component';

export const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
        { path: '', redirectTo: '/inicio', pathMatch: 'full' },
        {
          path: 'inicio',
          canActivate: [AuthGuard],
          data: {
              role: Role.Admin,
          },
          loadChildren: () => import('./pages/home/home.routes').then((m) => m.HOME_ROUTE),
        },

        {
          path: 'asociados',
          canActivate: [AuthGuard],
          data: {
            role: Role.Admin,
        },
          loadChildren: () => import('./pages/associates/associates.routes').then((m) => m.ASSOCIATES_ROUTE),
        },

        {
          path: 'usuarios',
          canActivate: [AuthGuard],
          data: {
            role: Role.Admin,
        },
          loadChildren: () => import('./pages/users-data/users.routes').then((m) => m.USERS_ROUTE),
        },

        {
          path: 'reclamaciones',
          canActivate: [AuthGuard],
          data: {
            role: Role.Admin,
        },
          loadChildren: () => import('./pages/reclamaciones/reclamaciones.routes').then((m) => m.RECLAMACIONES_ROUTE),
        },

        {
          path: 'construccion',
          canActivate: [AuthGuard],
          data: {
            role: Role.Admin,
        },
        loadChildren: () => import('./pages/construccion/construccion.routes').then((m) => m.CONSTRUCCION_ROUTE),
        },

        {
          path: 'documentacion',
          canActivate: [AuthGuard],
          data: {
            role: Role.Admin,
        },
        loadChildren: () => import('./pages/documentation/documentation.routes').then((m) => m.DOCUMENTATION_ROUTE),
        },
        {
          path: 'comunicacion',
          canActivate: [AuthGuard],
          data: {
            role: Role.Admin,
        },
        loadChildren: () => import('./pages/comunication/comunication.routes').then((m) => m.COMUNICATIONS_ROUTE),
        },
        {
          path: 'listados',
          canActivate: [AuthGuard],
          data: {
            role: Role.Admin,
        },
        loadChildren: () => import('./pages/listados/listados.routes').then((m) => m.LISTADOS_ROUTE),
        },
        {
          path: 'parametrizaciones',
          canActivate: [AuthGuard],
          data: {
            role: Role.Admin,
        },
        loadChildren: () => import('./pages/parametrizaciones/parametrizaciones.routes').then((m) => m.PARAMETRIZACIONES_ROUTE),
        },

        // {
        //   path: '**',
        //   canActivate: [AuthGuard],
        //   data: {
        //     role: Role.Admin,
        // },
        //   loadChildren: () => import('./pages/associates/notfound.routes.').then((m) => m.NOTFOUND_ROUTE),
        // },

        // {
        //   path: '**',
        //   canActivate: [AuthGuard],
        //   data: {
        //     role: Role.Admin,
        // },
        // component: Page404Component
        // },
        // {
        //     path: 'doctor',
        //     canActivate: [AuthGuard],
        //     data: {
        //         role: Role.Doctor,
        //     },
        //     loadChildren: () =>
        //         import('./doctor/doctor.routes').then((m) => m.DOCTOR_ROUTE),
        // },
        // {
        //     path: 'patient',
        //     canActivate: [AuthGuard],
        //     data: {
        //         role: Role.Patient,
        //     },
        //     loadChildren: () =>
        //         import('./patient/patient.routes').then((m) => m.PATIENT_ROUTE),
        // },

        // {
        //     path: 'calendar',
        //     loadChildren: () =>
        //         import('./calendar/calendar.routes').then((m) => m.CALENDAR_ROUTE),
        // },
        // {
        //     path: 'task',
        //     loadChildren: () =>
        //         import('./task/task.routes').then((m) => m.TASK_ROUTE),
        // },
        // {
        //     path: 'contacts',
        //     loadChildren: () =>
        //         import('./contacts/contacts.routes').then((m) => m.CONTACT_ROUTE),
        // },
        // {
        //     path: 'email',
        //     loadChildren: () =>
        //         import('./email/email.routes').then((m) => m.EMAIL_ROUTE),
        // },
        // {
        //     path: 'apps',
        //     loadChildren: () =>
        //         import('./apps/apps.routes').then((m) => m.APPS_ROUTE),
        // },
        // {
        //     path: 'widget',
        //     loadChildren: () =>
        //         import('./widget/widget.routes').then((m) => m.WIDGET_ROUTE),
        // },
        // {
        //     path: 'ui',
        //     loadChildren: () => import('./ui/ui.routes').then((m) => m.UI_ROUTE),
        // },
        // {
        //     path: 'forms',
        //     loadChildren: () =>
        //         import('./forms/forms.routes').then((m) => m.FORMS_ROUTE),
        // },
        // {
        //     path: 'tables',
        //     loadChildren: () =>
        //         import('./tables/tables.routes').then((m) => m.TEBLES_ROUTE),
        // },
        // {
        //     path: 'charts',
        //     loadChildren: () =>
        //         import('./charts/charts.routes').then((m) => m.CHART_ROUTE),
        // },
        // {
        //     path: 'timeline',
        //     loadChildren: () =>
        //         import('./timeline/timeline.routes').then((m) => m.TIMELINE_ROUTE),
        // },
        // {
        //     path: 'icons',
        //     loadChildren: () =>
        //         import('./icons/icons.routes').then((m) => m.ICONS_ROUTE),
        // },
        // {
        //     path: 'extra-pages',
        //     loadChildren: () =>
        //         import('./extra-pages/extra-pages.routes').then(
        //             (m) => m.EXTRA_PAGES_ROUTE
        //         ),
        // },
        // {
        //     path: 'multilevel',
        //     loadChildren: () =>
        //         import('./multilevel/multilevel.routes').then(
        //             (m) => m.MULTILEVEL_ROUTE
        //         ),
        // },
    ],
},
{
  path: 'login',
  component: AuthLayoutComponent,
  loadChildren: () => import('./pages/authentication/auth.routes').then((m) => m.AUTH_ROUTE),
},

// {
//   path: '**',
//   component: MainLayoutComponent,
//   loadChildren: () => import('./pages/associates/notfound.routes.').then((m) => m.NOTFOUND_ROUTE),
// },


];
