import { Direction, BidiModule } from '@angular/cdk/bidi';
import { Component, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { ConfigService } from '../../../core/services/config.service';
import { InConfiguration } from '../../../core/models/config.interface';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: [],
  standalone: true,
  imports: [BidiModule, RouterOutlet],
})
export class AuthLayoutComponent {
  direction!: Direction;
  public config!: InConfiguration;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private configService: ConfigService,
    private renderer: Renderer2
  ) {
    this.config = this.configService.configData;

    if (this.config) {
      if (this.config.layout.rtl === true) {
        this.direction = 'rtl';
        localStorage.setItem('isRtl', 'true');
      } else {
        this.direction = 'ltr';
        localStorage.setItem('isRtl', 'false');
      }
    }
    this.renderer.addClass(this.document.body, this.config.layout.variant);
  }
}
