<nav #navbar class="navbar active" [ngClass]="{'top-header-area': env === 'test', 'top-header-area-prod': env === 'prod'}">
  <div class="container-fluid">
    <!-- Header original con logo -->
    <!-- <div class="navbar-header" style="padding: 4px;">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink={{homePage}} style="height: auto;">
        <img src="assets/images/union3.png" class="img-fluid" alt="" />
        <span class="logo-name">Montepío del Igualatorio</span>
      </a>
    </div> -->

    <div class="collapse navbar-collapse" [ngClass]="isNavbarCollapsed ? '' : 'show'">
      <!-- Botón de menú lateral -->
      <ul class="float-start collapse-menu-icon" style="padding-left: 0px;">
        <li>
          <button mat-button (click)="callSidemenuCollapse()" class="nav-notification-icons">
            <span class="notranslate">
              <i id="menuIcon" style="color: #D9DADA;" class="material-icons-outlined icon-color">
                {{ !isOpenSidebar ? 'close' : 'menu' }}
              </i>
            </span>
          </button>
        </li>
      </ul>

      <!-- Logo y título -->
      <ul class="nav navbar-nav navbar-left">
        <li class="nav-item user_profile">
          <div class="chip dropdown-toggle" ngbDropdownToggle>
            <span style="margin-right: 15px;">
              <a routerLink="/inicio">
                <img src="assets/images/logotipo1.png" class="img-fluid gestion_logo" alt="" />
              </a>
            </span>
            <a routerLink="/inicio">
              <span class="gestion_title">GESTIÓN MONTEPÍO DEL IGUALATORIO</span>
            </a>
          </div>
        </li>
      </ul>

      <ul class="nav navbar-nav navbar-right">
        <!-- Botón de pantalla completa -->
        <li class="fullscreen">
          <button mat-button class="nav-notification-icons" (click)="callFullscreen()">
            <span class="notranslate">
              <i class="material-icons-outlined icon-color" style="color: #D9DADA;">
                {{ isFullScreen ? 'fullscreen_exit' : 'fullscreen' }}
              </i>
            </span>
          </button>
        </li>
        <!-- #END# Full Screen Button -->

        <!-- #START# Notifications-->
        <!-- Comentario original mantenido -->
        <!-- <li class="notifications">
          <button mat-button [matMenuTriggerFor]="notificationMenu" class="nav-notification-icons">
            <span class="notranslate"><i class="material-icons-outlined icon-color" style="color: #D9DADA;">notifications_active</i></span>
          </button>
          <mat-menu #notificationMenu="matMenu" class="nfc-menu">
            <div class="nfc-header">
              <h5 class="mb-0">Notitications</h5>
              <a class="nfc-mark-as-read">Mark all as read</a>
            </div>
            <div class="nfc-dropdown">
              <ng-scrollbar style="height: 350px" visibility="hover">
                <div class="noti-list header-menu">
                  <div class="menu">
                    <div>
                      @for (notification of notifications; track notification) {
                      <button mat-menu-item onClick="return false;" [ngClass]="[notification.status]">
                        <span class="table-img msg-user ">
                          <i class="material-icons-outlined nfc-type-icon"
                            [ngClass]="[notification.color]">{{notification.icon}}</i>
                        </span>
                        <span class="menu-info">
                          <span class="menu-title">{{notification.message}}</span>
                          <span class="menu-desc mt-2">
                            <i class="material-icons">access_time</i> {{notification.time}}
                          </span>
                        </span>
                        <span class="nfc-close">
                          <app-feather-icons [icon]="'x'" [class]="'user-menu-icons'"></app-feather-icons>
                        </span>
                      </button>
                      }
                    </div>
                  </div>
                </div>
              </ng-scrollbar>
            </div>
            <div class="nfc-footer">
              <a class="nfc-read-all">Read All Notifications</a>
            </div>
          </mat-menu>
        </li> -->
        <!-- #END# Notifications-->

        <!-- #START# Perfil de usuario -->
        <li class="nav-item user_profile">
          <button mat-button [matMenuTriggerFor]="profilemenu">
            <div class="chip dropdown-toggle">
              <span class="nameHeader">{{ this.commonService.nombre }}</span>
              <span class="notranslate">
                <i class="fas fa-user-circle" style="color: #D9DADA;"></i>
              </span>
            </div>
          </button>
          <mat-menu #profilemenu="matMenu" class="profile-menu">
            <div class="noti-list">
              <div class="menu">
                <div class="user_dw_menu">
                  <button mat-menu-item class="user-item-list" (click)="miAccount()">
                    <app-feather-icons icon="user" class="user-menu-icons"></app-feather-icons> 
                    {{ accountMenu | translate }}
                  </button>
                  <button mat-menu-item (click)="logout()" class="user-item-list">
                    <app-feather-icons icon="log-out" class="user-menu-icons"></app-feather-icons> 
                    {{ logOutMenu | translate }}
                  </button>
                </div>
              </div>
            </div>
          </mat-menu>
        </li>
        <!-- #END# Perfil de usuario -->
      </ul>
    </div>
  </div>
</nav>
